import { render, staticRenderFns } from "./AdditionalInfoTab.vue?vue&type=template&id=1b49edc9&"
import script from "./AdditionalInfoTab.vue?vue&type=script&lang=js&"
export * from "./AdditionalInfoTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/travis/build/LondonBoroughSutton/sutton-information-hub-v2-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b49edc9')) {
      api.createRecord('1b49edc9', component.options)
    } else {
      api.reload('1b49edc9', component.options)
    }
    module.hot.accept("./AdditionalInfoTab.vue?vue&type=template&id=1b49edc9&", function () {
      api.rerender('1b49edc9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/services/forms/AdditionalInfoTab.vue"
export default component.exports